// extracted by mini-css-extract-plugin
export var category = "post-blog-module--category--aacbb";
export var date = "post-blog-module--date--80274";
export var info = "post-blog-module--info--4d27d";
export var postAuthor = "post-blog-module--postAuthor--e15cd";
export var postAuthorImg = "post-blog-module--postAuthorImg--90c89";
export var postAuthorName = "post-blog-module--postAuthorName--d1d30";
export var postImg = "post-blog-module--postImg--af4c3";
export var postItem = "post-blog-module--postItem--fcb3a";
export var readingTime = "post-blog-module--readingTime--95dbc";
export var title = "post-blog-module--title--2ad84";